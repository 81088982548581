import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['card', 'button', 'pagination'];

    static outlets = ['progressbar'];

    initialize() {
        this.cardTargets.forEach((card) => {
            card.classList.add('tw-hidden');
        });

        if (this.hasPaginationTarget) {
            this.paginationTarget.classList.add('tw-hidden');
        }
    }

    showMore() {
        this.cardTargets.forEach((card) => {
            card.classList.remove('tw-hidden');
        });

        this.buttonTarget.classList.add('tw-hidden');

        if (this.hasPaginationTarget) {
            this.paginationTarget.classList.remove('tw-hidden');
        }

        this.progressbarOutlet.currentProgressValue = this.progressbarOutlet.maxProgressValue;
    }
}
