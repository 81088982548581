import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        endpoint: String,
    };

    accept() {
        fetch(this.endpointValue, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then((response) => {
                if (response.ok) {
                    window.location.reload();
                }
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error('Error:', error));
    }
}
