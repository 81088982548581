const weboramaComponent = {
    init() {
        if (document.getElementById('weborama_script')) {
            const user = JSON.parse(sessionStorage.getItem('user'));
            if (user !== null) {
                this.triggerWeboramaEvent();
            } else {
                document.addEventListener('userSessionUpdated', this.triggerWeboramaEvent.bind(this), { once: true });
            }
        }
    },
    triggerWeboramaEvent() {
        const user = JSON.parse(sessionStorage.getItem('user'));

        document.dispatchEvent(
            new CustomEvent('triggerWeborama', {
                detail: {
                    id_my: user?.content?.user?.hashed_id ?? null,
                    current_page: window.location.origin + window.location.pathname,
                },
            }),
        );
    },
};

export default weboramaComponent;
