// eslint-disable-next-line import/extensions
import 'lazysizes/plugins/bgset/ls.bgset';
import '../scss/main.scss';

// Only modules share between pages & critical
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import lazySizes from 'lazysizes';

import adsComponent from './components/adsComponent/ads';
import adsNativeComponent from './components/adsComponent/adsNative';
import dataLayerEvents from './components/dataLayerEvents/dataLayerEvents';
import datePickerComponent from './components/datePickerComponent/datePickerComponent';
import headerComponent from './components/headerComponent/headerComponent';
import imageMediaComponent from './components/ImagesMediaComponent/imageMediaComponent';
import masonryGrid from './components/masonryGrid/masonryGrid';
import footerMenu from './components/menu/footerMenu';
import sso from './components/sso/sso';
import weboramaComponent from './components/weboramaComponent/weboramaComponent';

weboramaComponent.init();
// To keep in first position. The synchronous call to recover the current user is made here.
sso.init();

window.Stimulus = Application.start();
// window.Stimulus.debug = true;
window.Stimulus.load(definitionsFromContext(require.context('./controllers', true, /\.js$/)));
window.Stimulus.load(definitionsFromContext(require.context('./controllers/menu', true, /\.js$/)));
window.Stimulus.load(definitionsFromContext(require.context('./controllers/sso', true, /\.js$/)));

// Init modules that require DOM AND assets to be fully loaded here
function onPageFullyLoaded() {
    // Wait until the page is fully loaded before executing Masonry, otherwise some elements height might be changed by
    // images or css loading after the js is executed
    masonryGrid.init();
    imageMediaComponent.init();
}

// Init modules that only need the DOM to be ready here (assets, images and CSS might not be fully loaded here)
function onDomReady() {
    const addClassOnEventComponent = document.querySelectorAll('[data-add-remove-class-element]');
    const autoCompleteComponent = document.querySelectorAll('[data-component=autocomplete]');
    const backLinkComponent = document.querySelectorAll('[data-back-link]');
    const chartComponent = document.querySelectorAll('[data-chart]');
    const jsaModalComponent = document.querySelectorAll('[data-modal]');
    const modalComponent = document.querySelectorAll('[data-modal-name]');
    const multiSelectComponent = document.querySelectorAll('[data-component=multiSelectHidden]');
    const backFormButtonComponent = document.querySelector('[backFormButtonComponent]');
    const sliderStoryComponent = document.querySelectorAll('[data-slider-story]');
    const selectComponent = document.querySelectorAll('[data-component=selectHidden]');
    const selectCityComponent = document.querySelector('[data-select-city]');
    const selectFilterComponent = document.querySelectorAll('[data-component=selectFilterHidden]');
    const sliderListeDeVideoComponent = document.querySelectorAll('[data-slider-liste-de-video]');
    const sliderDiaporamaComponent = document.querySelectorAll('[data-slider-diaporama]');
    const sliderSommaireTestsComponent = document.querySelectorAll('[data-slider-sommaire-tests]');
    const sliderSommaireComponent = document.querySelectorAll('[data-slider-sommaire]');
    const sliderLesEntreprisesRecrutentComponent = document.querySelectorAll('[data-slider-entreprisesRecrutent]');
    const seeMoreComponent = document.querySelectorAll('[data-see-more-container]');
    const showHideFormPartComponent = document.querySelectorAll('[data-shfp-part-parents]');
    const paymentBankCallComponent = document.querySelector('[data-jsa-pay-data]');
    const uploadComponent = document.querySelectorAll('[data-component="upload-file"]');
    const tabsComponent = document.querySelectorAll('[data-component=tabs]');
    const toasterComponent = document.querySelectorAll('[data-component=toaster]');
    const toggleComponent = document.querySelectorAll('[data-toggle-target]');
    const anchorTargetPolicyComponent = document.querySelectorAll('[data-component=anchor-target-policy]');
    const dataDrivenComponent = document.querySelectorAll('[data-component=dataDriven]');
    const liverampComponent = document.querySelectorAll('[data-component=liveramp]');
    const uploadAndSaveFileComponent = document.querySelectorAll('[data-component="uploadAndSaveFile"]');
    const hideOnLoadComponent = document.querySelectorAll('[data-hide-onload]');
    const resetFormComponent = document.querySelectorAll('[data-reset-form]');
    const schoolsMapComponent = document.querySelectorAll('[data-map-display]');
    const formComponent = document.querySelectorAll('form');
    const coreBlockSocialNetworkComponent = document.querySelectorAll('[data-block-social-network]');
    const leafletComponent = document.querySelectorAll('[data-leaflet]');
    const ospNavbarComponent = document.querySelector('#main-osp-navbar');
    const truncateTextComponent = document.querySelectorAll('[data-text-truncate]');
    const comparatorComponent = document.querySelector('[data-component="comparator"]');
    const comparatorFormationComponent = document.querySelector('[data-component="comparatorFormation"]');
    const horizontalStickyComponent = document.querySelectorAll('[data-component="horizontalSticky"]');
    const tooltipComponent = document.querySelectorAll('[data-component="tooltip"]');
    const horizontalScrollButtonComponent = document.querySelectorAll('[data-component="horizontalScrollButton"]');
    const jcsResultComponent = document.querySelectorAll('[data-jcs-result-result]');
    const jcsAnswerSelectionComponent = document.querySelectorAll("[id^='question_question-']");
    const jcsProfileExplanationComponent = document.querySelectorAll('[data-jcs-profile-explanation-trigger]');
    const jcsNextFormModal = document.querySelectorAll('#nextFormModal');
    const depositFormAdvertInfoStepComponent = document.querySelector('[data-form-id="jsa-deposit-advert-info"]');
    const depositFormApplicationStepComponent = document.querySelector('[data-form-id="jsa-deposit-application"]');
    const depositFormCommercialOptionsStepComponent = document.querySelector('[data-form-id="jsa-deposit-commercial-options"]');
    const autocompleteMultipleComponent = document.querySelectorAll('[data-component=autocompleteMultiple]');
    const retargetingComponent = document.querySelectorAll('[data-tgm-tags]');
    const emailDomainSuggester = document.querySelectorAll('[data-email-domain-suggester]');
    const collapsableComponent = document.querySelectorAll('[data-collapsable]');
    const ssoProfileComponent = document.querySelector('[data-component="sso-profile"]');

    // eslint-disable-next-line no-unused-expressions
    adsComponent; // Keep in first position to display ads on page
    // eslint-disable-next-line no-unused-expressions
    adsNativeComponent; // Keep in second$ position to display ads on page
    // eslint-disable-next-line no-unused-expressions
    headerComponent;
    // eslint-disable-next-line no-unused-expressions
    footerMenu;
    // eslint-disable-next-line no-unused-expressions
    datePickerComponent;
    masonryGrid.init();
    dataLayerEvents.init();

    // page is optimized for fast onload event
    Object.assign(lazySizes.cfg, {
        loadMode: 2,
    });

    if (chartComponent.length !== 0) {
        // chartComponent
        import(
            /* webpackChunkName: "chart" */ './components/chart/chartComponent'
        ).then((component) => component);
    }

    if (sliderStoryComponent.length !== 0) {
        // sliderStoryComponent
        import(
            /* webpackChunkName: "sliderStoryComponent" */ './components/slider/sliderStoryComponent'
        ).then((component) => component);
    }

    if (autoCompleteComponent.length !== 0) {
        // autoCompleteComponent
        import(
            /* webpackChunkName: "autocompleteComponent" */ './components/autocompleteComponent/autocompleteComponent'
        ).then((component) => component);
    }

    if (selectCityComponent !== null) {
        // selectCityComponent
        import(
            /* webpackChunkName: "selectCityComponent" */ './components/select/selectCityComponent/selectCityComponent'
        ).then((component) => component);
    }

    if (selectFilterComponent.length !== 0) {
        // selectFilterComponent
        import(
            /* webpackChunkName: "selectFilterComponent" */ './components/select/selectFilterComponent/selectFilterComponent'
        ).then((component) => component);
    }

    if (sliderListeDeVideoComponent.length !== 0) {
        // sliderListeDeVideoComponent
        import(
            /* webpackChunkName: "sliderListeDeVideoComponent" */ './components/slider/sliderListeDeVideoComponent'
        ).then((component) => component);
    }

    if (sliderDiaporamaComponent.length !== 0) {
        // sliderDiaporamaComponent
        import(
            /* webpackChunkName: "sliderDiaporamaComponent" */ './components/slider/sliderDiaporamaComponent'
        ).then((component) => component);
    }

    if (sliderSommaireTestsComponent.length !== 0) {
        // sliderSommaireTestsComponent
        import(
            /* webpackChunkName: "sliderSommaireTestsComponent" */ './components/slider/sliderSommaireTestsComponent'
        ).then((component) => component);
    }

    if (sliderSommaireComponent.length !== 0) {
        // sliderSommaireComponent
        import(
            /* webpackChunkName: "sliderSommaireComponent" */ './components/slider/sliderSommaireComponent'
        ).then((component) => component);
    }

    if (sliderLesEntreprisesRecrutentComponent.length !== 0) {
        // sliderLesEntreprisesRecrutentComponent
        import(
            /* webpackChunkName: "sliderLesEntreprisesRecrutentComponent" */ './components/slider/sliderLesEntreprisesRecrutentComponent'
        ).then((component) => component);
    }

    if (seeMoreComponent.length !== 0) {
        // seeMoreComponent
        import(
            /* webpackChunkName: "seeMoreComponent" */ './components/seeMoreComponent/seeMoreComponent'
        ).then((component) => component);
    }

    // shareAndCopyLinkComponent
    import(
        /* webpackChunkName: "shareAndCopyLinkComponent" */ './components/modal/shareAndCopyLinkComponent'
    ).then((component) => component);

    if (backLinkComponent.length !== 0) {
        // backLinkComponent
        import(
            /* webpackChunkName: "backLinkComponent" */ './components/backLinkComponent/backLinkComponent'
        ).then((component) => component);
    }

    if (multiSelectComponent.length !== 0) {
        // multiSelectComponent
        import(
            /* webpackChunkName: "multiSelectComponent" */ './components/select/multiSelectComponent/multiSelectComponent'
        ).then((component) => component.default.init());
    }

    if (selectComponent.length !== 0) {
        // selectComponent
        import(
            /* webpackChunkName: "selectComponent" */ './components/select/selectComponent/selectComponent'
        ).then((component) => component);
    }

    if (addClassOnEventComponent.length !== 0) {
        // addClassOnEventComponent
        import(
            /* webpackChunkName: "addClassOnEventComponent" */ './components/addClassOnEventComponent/addClassOnEventComponent'
        ).then((component) => component);
    }

    if (showHideFormPartComponent.length !== 0) {
        // showHideFormPartComponent
        import(
            /* webpackChunkName: "showHideFormPartComponent" */ './components/showHideFormPartComponent/showHideFormPartComponent'
        ).then((component) => component);
    }

    if (uploadComponent.length !== 0) {
        // uploadComponent
        import(
            /* webpackChunkName: "uploadComponent" */ './components/uploadComponent/uploadComponent'
        ).then((component) => component);
    }

    if (backFormButtonComponent !== null) {
        // backFormButtonComponent
        import(
            /* webpackChunkName: "backFormButtonComponent" */ './components/jsa/backFormButtonComponent/backFormButtonComponent'
        ).then((component) => component);
    }

    if (paymentBankCallComponent !== null) {
        // paymentBankCallComponent
        import(
            /* webpackChunkName: "paymentBankCallComponent" */ './components/jsa/paymentBankCallComponent/paymentBankCallComponent'
        ).then((component) => component);
    }

    if (jsaModalComponent.length !== 0) {
        // jsaModalComponent
        import(
            /* webpackChunkName: "jsaModalComponent" */ './components/modal/jsaModalComponent'
        ).then((component) => component);
    }

    if (modalComponent.length !== 0) {
        // modalComponent
        import(
            /* webpackChunkName: "modalComponent" */ './components/modal/modalComponent'
        ).then((component) => component);
    }

    if (tabsComponent.length !== 0) {
        // tabs
        import(
            /* webpackChunkName: "tabs" */ './components/tabs/tabs'
        ).then((component) => component);
    }

    if (toasterComponent.length !== 0) {
        // toaster
        import(
            /* webpackChunkName: "toaster" */ './components/toaster/toaster'
        ).then((component) => component);
    }

    if (toggleComponent.length !== 0) {
        // toggles
        import(
            /* webpackChunkName: "toggles" */ './components/toggle/toggle'
        ).then((component) => component);
    }

    if (anchorTargetPolicyComponent.length !== 0) {
        // anchorTargetPolicyComponent
        import(
            /* webpackChunkName: "anchorTargetPolicyComponent" */ './components/anchorTargetPolicyComponent/anchorTargetPolicyComponent'
        ).then((component) => component);
    }

    if (dataDrivenComponent.length !== 0) {
        // dataDrivenComponent
        import(
            /* webpackChunkName: "dataDrivenComponent" */ './components/dataDrivenComponent/dataDrivenComponent'
        ).then((component) => component);
    }

    if (liverampComponent.length !== 0) {
        // liverampComponent
        import(
            /* webpackChunkName: "liverampComponent" */ './components/liverampComponent/liverampComponent'
        ).then((component) => component);
    }

    if (uploadAndSaveFileComponent.length !== 0) {
        // uploadAndSaveFileComponent
        import(
            /* webpackChunkName: "uploadAndSaveFileComponent" */ './components/uploadAndSaveFileComponent/uploadAndSaveFileComponent'
        ).then((component) => component);
    }

    if (hideOnLoadComponent.length !== 0) {
        // hideOnLoadComponent
        import(
            /* webpackChunkName: "hideOnLoadComponent" */ './components/hideOnLoadComponent/hideOnLoadComponent'
        ).then((component) => component);
    }

    if (resetFormComponent.length !== 0) {
        // resetFormComponent
        import(
            /* webpackChunkName: "resetFormComponent" */ './components/resetFormComponent/resetFormComponent'
        ).then((component) => component);
    }

    if (schoolsMapComponent.length !== 0) {
        // schoolsMapComponent
        import(
            /* webpackChunkName: "schoolsMapComponent" */ './components/edu/map/schoolsMapComponent'
        ).then((component) => component);
    }

    if (formComponent.length !== 0) {
        // formComponent
        import(
            /* webpackChunkName: "formComponent" */ './components/form/form'
        ).then((component) => component);
    }

    if (truncateTextComponent.length !== 0) {
        // truncateTextComponent
        import(
            /* webpackChunkName: "truncateTextComponent" */ './components/truncateTextComponent/truncateTextComponent'
        ).then((component) => component);
    }

    if (coreBlockSocialNetworkComponent.length !== 0) {
        // coreBlockSocialNetworkComponent
        import(
            /* webpackChunkName: "coreBlockSocialNetworkComponent" */ './components/core/block/socialNetworkBlockComponent'
        ).then((component) => component);
    }

    if (leafletComponent.length !== 0) {
        // leafletComponent
        import(
            /* webpackChunkName: "formComponent" */ './components/leaflet/leaflet'
        ).then((component) => component);
    }

    if (comparatorComponent !== null) {
        // comparatorComponent
        import(
            /* webpackChunkName: "comparatorComponent" */ './components/comparatorComponent/comparatorComponent'
        ).then((component) => component);
    }

    if (comparatorFormationComponent !== null) {
        // comparatorFormationComponent
        import(
            /* webpackChunkName: "comparatorFormationComponent" */ './components/comparatorFormationComponent/comparatorFormationComponent'
        ).then((component) => component);
    }

    if (ospNavbarComponent !== null) {
        // ospNavbarComponent
        import(
            /* webpackChunkName: "ospNavbarComponent" */ './components/osp/navbarComponent'
        ).then((component) => component);
    }

    if (horizontalStickyComponent.length !== 0) {
        // horizontalStickyComponent
        import(
            /* webpackChunkName: "horizontalStickyComponent" */ './components/horizontalStickyComponent/horizontalStickyComponent'
        ).then((component) => component);
    }

    if (tooltipComponent.length !== 0) {
        // tooltipComponent
        import(
            /* webpackChunkName: "tooltipComponent" */ './components/tooltipComponent/tooltipComponent'
        ).then((component) => component);
    }

    if (horizontalScrollButtonComponent.length !== 0) {
        // horizontalScrollButtonComponent
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "horizontalScrollButtonComponent" */ './components/horizontalScrollButtonComponent/horizontalScrollButtonComponent'
        ).then((component) => component);
    }

    if (jcsResultComponent.length !== 0) {
        // jcsResultComponent
        import(
            /* webpackChunkName: "jcsResultComponent" */ './components/jcs/answerResultComponent'
        ).then((component) => component);
    }

    if (jcsAnswerSelectionComponent.length !== 0) {
        // jcsAnswerSelectionComponent
        import(
            /* webpackChunkName: "jcsAnswerSelectionComponent" */ './components/jcs/answerSelectionComponent'
        ).then((component) => component);
    }

    if (jcsProfileExplanationComponent.length !== 0) {
        // jcsProfileExplanationComponent
        import(
            /* webpackChunkName: "jcsProfileExplanationComponent" */ './components/jcs/profileExplanationComponent'
        ).then((component) => component);
    }

    if (jcsNextFormModal.length !== 0) {
        // jcsNextFormModal
        import(
            /* webpackChunkName: "jcsNextFormModal" */ './components/jcs/nextFormModal'
        ).then((component) => component);
    }

    if (depositFormAdvertInfoStepComponent !== null) {
        // depositFormAdvertInfoStepComponent
        import(
            /* webpackChunkName: "depositFormAdvertInfoStepComponent" */ './components/jsa/depositFormComponent/depositFormAdvertInfoStep'
        ).then((component) => component);
    }

    if (depositFormApplicationStepComponent !== null) {
        // depositFormApplicationStepComponent
        import(
            /* webpackChunkName: "depositFormApplicationStepComponent" */ './components/jsa/depositFormComponent/depositFormApplicationStep'
        ).then((component) => component);
    }

    if (depositFormCommercialOptionsStepComponent !== null) {
        // depositFormCommercialOptionsStepComponent
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "depositFormCommercialOptionsStepComponent" */ './components/jsa/depositFormComponent/depositFormCommercialOptionStep'
        ).then((component) => component);
    }

    if (autocompleteMultipleComponent.length !== 0) {
        // autocompleteMultipleComponent
        import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "autocompleteMultipleComponent" */ './components/autocompleteMultipleComponent/autocompleteMultipleComponent'
        ).then((component) => component);
    }

    if (retargetingComponent.length !== 0) {
        // retargetingComponent
        import(
            /* webpackChunkName: "retargetingComponent" */ './components/retargeting/retargeting'
        ).then((component) => component);
    }

    if (emailDomainSuggester.length !== 0) {
        // emailDomainSuggester
        import(
            /* webpackChunkName: "emailDomainSuggester" */ './components/emailDomainSuggester/emailDomainSuggester'
        ).then((component) => {
            component.default.init();
        });
    }

    if (collapsableComponent.length !== 0) {
        // collapsableComponent
        import(
            /* webpackChunkName: "collapsableComponent" */ './components/collapsable/collapsable'
        ).then((component) => component);
    }

    if (ssoProfileComponent !== null) {
        // ssoProfileComponent
        import(
            /* webpackChunkName: "ssoProfileComponent" */ './components/sso/profile/profile'
        ).then((component) => component);
    }
}

document.addEventListener('DOMContentLoaded', onDomReady, false);
window.addEventListener('load', onPageFullyLoaded, false);
